// import {initializeApp} from 'firebase/app'
import {getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore"; 
import { getStorage } from 'firebase/storage';
// import { getAnalytics } from 'firebase/analytics';



// const firebaseConfig = {
//   apiKey: `${process.env.REACT_APP_API_KEY}`,
//   authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
//   projectId: `${process.env.REACT_APP_PROJECT_ID}`,
//   storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
//   messagingSenderId: `${process.env.REACT_APP_MESS_SEND_ID}`,
//   appId: `${process.env.REACT_APP_API_ID}`,
//   measurementId: "G-T7VFDPQZVG"
  
// };

// // Initialize firebase 
// const app = initializeApp(firebaseConfig);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC78p1ezLqww27S10hCaMhGAK3gBgN_hLY",
  authDomain: "mind-7eadb.firebaseapp.com",
  projectId: "mind-7eadb",
  storageBucket: "mind-7eadb.appspot.com",
  messagingSenderId: "170155296919",
  appId: "1:170155296919:web:856ea632635299c50001d2",
  measurementId: "G-T7VFDPQZVG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

var authentication = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();

export {authentication,db ,storage};
