import React, { useEffect, useRef, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../mindapp/firebase";

function Message({ chatid }) {
  const [messages, setMessages] = useState([]);

  const getAllMessages = async () => {
    const q = query(
      collection(db, `messages/${chatid}/message`),
      orderBy("sendat", "asc")
    );
    onSnapshot(q, (querySnapshot) => {
      const storedMessages = [];
      querySnapshot.forEach((doc) => {
        const message = doc.data();
        // Adding formatted date and time properties to each message
        message.formattedDate = new Date(
          message.sendat.seconds * 1000
        ).toLocaleDateString("en-US");
        message.formattedTime = new Date(
          message.sendat.seconds * 1000
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        storedMessages.push(message);
      });
      setMessages(storedMessages);
    });
  };

  const ref = useRef();

  useEffect(() => {
    getAllMessages();
  }, [chatid]);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const user = localStorage.getItem("user");

  // Function to check if the current message is the first of a new day
  const isFirstMessageOfDay = (index) => {
    if (index === 0) return true;
    return messages[index].formattedDate !== messages[index - 1].formattedDate;
  };

  return (
    <div
      className="messages-container"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
        maxHeight: "90vh",
        overflowY: "auto",
        scrollbarWidth: "none", // Firefox
        msOverflowStyle: "none", // IE and Edge
      }}
    >
      <style>
        {`.messages-container::-webkit-scrollbar { display: none; }`}{" "}
        {/* Chrome, Safari, Opera */}
      </style>

      {messages.length > 0 &&
        messages.map((m, index) => (
          <React.Fragment key={index}>
            {isFirstMessageOfDay(index) && (
              <div
                style={{
                  textAlign: "center",
                  padding: "10px 0",
                  fontSize: "0.85rem",
                  color: "#666",
                  width: "100%",
                  fontWeight: "bold", // Make the date bold
                }}
              >
                {m.formattedDate}
              </div>
            )}
            <div
              style={{
                maxWidth: "75%",
                marginLeft: m.sender === user ? "auto" : "10%",
                marginRight: m.sender === user ? "10%" : "auto",
                backgroundColor: m.sender === user ? "#DCF8C6" : "#FFF",
                borderRadius: "20px",
                padding: "10px",
                boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                wordBreak: "break-word",
                alignItems: m.sender === user ? "flex-end" : "flex-start", // Align text based on the sender
              }}
            >
              <div>{m.text}</div>
              <div
                style={{
                  fontSize: "0.75rem",
                  color: "#999",
                  marginTop: "4px",
                }}
              >
                {m.formattedTime}
              </div>
            </div>
          </React.Fragment>
        ))}
      <div ref={ref} />
    </div>
  );
}

export default Message;
