import { Timestamp } from "firebase/firestore";

export default function daysLeft(timestamp, subscriptionDays) {
  const currentTimestamp = Timestamp.fromDate(new Date());
  const timeElapsed = (currentTimestamp.seconds) - (timestamp.seconds);
  const timeElapsedDays = Math.floor(timeElapsed / (24 * 60 * 60));
  const daysLeft = (subscriptionDays - timeElapsedDays) > 0
    ? (subscriptionDays - timeElapsedDays)
    : 0;
  return daysLeft;
}