import "./MemberCard.css";
import { doc, getDoc, setDoc, Timestamp, } from "firebase/firestore";
import { db } from '../../mindapp/firebase';
import { useNavigate } from "react-router-dom";
import studentimg from "../Navbar/DashboardTab/img/student.png";
import adminimg from "../Navbar/DashboardTab/img/admin.png";
import Menteecount from "../../Mentor/Menteecount";
import Transaction from "../../Admin/Transaction/Transaction";

export default function MemberCard({ member }) {

  const navigate = useNavigate();

  const profilePath = member.type === "mentor"
    ? `/dashboard/profile/mentor/${member.id}`
    : `/dashboard/profile/mentee/${member.id}`;

  const userid = localStorage.getItem('user');
  const username = localStorage.getItem('username');
  const type = localStorage.getItem("type");


  const createChat = async (menteeid, menteename) => {
    const chatid = userid + "chat" + menteeid;

    const UserdocRef = doc(db, "messages", chatid);
    const UserdocSnap = await getDoc(UserdocRef);

    console.log(menteeid)
    if (UserdocSnap.exists()) {
      console.log("chat exists")
      navigate("/dashboard/chat")


    } else {
      // setAnimate(true)


      const chatid = userid + "chat" + menteeid;

      console.log("chatid is ", chatid)
      await setDoc(doc(db, "messages", chatid), {
        createdat: Timestamp.fromDate(new Date()),
        mentor: username,
        student: menteename,
        mentee_id: menteeid,
        mentor_id: userid,
        sendat: Timestamp.fromDate(new Date()),

      });

      console.log("chat created")
      navigate("/dashboard/chat")

    }


    console.log("chatid is ", chatid)

  }

  return (
    <div className="member-card-container">

      <div className="member-card-img-container">
        {
          member.type === "mentor"
            ? <img src={member.img ? member.img : adminimg} alt="" style={{ width: "35px", height: "35px", objectFit: "cover", borderRadius: "50%", cursor: "pointer" }} />
            : <img src={studentimg} alt="" style={{ width: "35px", height: "35px", cursor: "pointer" }} />
        }
      </div>

      <div className="member-card-body">

        <div className="member-card-info-container">
          <div className="member-card-header-container">
            <h2 className="member-card-name">{member.name}</h2>
            <div style={{ display: "flex" }}>
              {
                member.type === "mentor"
                  ? (<div style={{ display: "flex" }}>
                    {member.mentee_count} <Menteecount mentor_id={member.id} mentorname={member.name} />
                  </div>)
                  : null
              }
              {
                type === "admin" && member.type === "mentor"
                  ? <><Transaction home={false} all={true} transaction_data={member} id={member.id} style={{ cursor: "pointer", }} /></>
                  : null
              }
            </div>
            
          </div>
          <h4 className="member-card-field">{member.type === "mentor" ? member.feild : `${member.preparing_for} aspirant`}</h4>
        </div>

        <div className="member-btn-container">
          <button onClick={() => createChat(member.id, member.name)}>Chat</button>
          <button onClick={() => navigate(profilePath)}>Profile</button>
        </div>

      </div>

    </div>
  );
}